.services-hero {
    background-color: var(--light-gray);
    background: linear-gradient(135deg, var(--light-gray) 0%, #F3EBFF 100%);
    padding: 6rem 0 4rem;
    text-align: center;
  }
  
  .services-hero h1 {
    font-size: 3rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
    opacity: 0;
    animation: fadeInDown 1s ease forwards;
  }
  
  .services-hero p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.2rem;
    color: var(--text-color);
    opacity: 0;
    animation: fadeIn 1s ease forwards 0.5s;
  }
  
  /* Packages Section */
  .packages-section {
    padding: 4rem 0;
    background-color: var(--white);
  }
  
  .packages-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: var(--secondary-color);
  }
  
  .packages-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 2rem;
  }
  
  /* Package Cards */
  .package-card {
    background-color: var(--white);
    border: 1px solid var(--light-gray);
    border-radius: 12px;
    padding: 2rem;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .package-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
  }
  
  .package-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, var(--primary-color), var(--accent-color));
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .package-card:hover::before {
    opacity: 1;
  }
  
  /* Package Header */
  .package-header {
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--light-gray);
  }
  
  .package-name {
    font-size: 1.8rem;
    color: var(--secondary-color);
    margin-bottom: 0.5rem;
  }
  
  .package-price {
    font-size: 2.5rem;
    color: var(--primary-color);
    font-weight: bold;
    margin: 1rem 0;
  }
  
  .package-description {
    color: var(--text-color);
    margin-bottom: 1.5rem;
  }
  
  /* Features List */
  .features-list {
    list-style: none;
    margin: 2rem 0;
    flex-grow: 1;
    padding: 0;
  }
  
  .features-list li {
    margin-bottom: 1rem;
    padding-left: 2rem;
    position: relative;
    color: var(--text-color);
  }
  
  .features-list li::before {
    content: '✓';
    color: var(--primary-color);
    position: absolute;
    left: 0;
    font-weight: bold;
  }
  
  /* Package Button */
  .package-button {
    display: inline-block;
    width: 100%;
    padding: 1rem 2rem;
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin-top: auto;
  }
  
  .package-button:hover {
    background-color: var(--primary-hover);
    transform: translateY(-2px);
  }
  
  /* Portfolio Section */
  .portfolio-section {
    padding: 4rem 0;
    background-color: #F9F5FF;
  }
  
  .portfolio-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: var(--secondary-color);
  }
  
  .portfolio-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 2rem;
  }
  
  .portfolio-item {
    background: var(--white);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: var(--shadow-md);
    transition: all 0.3s ease;
    border: 1px solid rgba(126, 58, 242, 0.1); /* Very subtle purple border */
  }
  
  .portfolio-item:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
    border-color: var(--accent-color);
  }
  
  /* Video Container */
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  /* Portfolio Content */
  .portfolio-content {
    padding: 1.5rem;
  }
  
  .portfolio-content h3 {
    margin-bottom: 1rem;
    color: var(--secondary-color);
    font-size: 1.4rem;
  }
  
  .portfolio-content p {
    color: var(--text-color);
    margin-bottom: 0.5rem;
    line-height: 1.6;
  }
  
  /* Animation Keyframes */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-hero h1 {
      font-size: 2.5rem;
    }
  
    .packages-grid,
    .portfolio-grid {
      grid-template-columns: 1fr;
    }
  
    .package-card {
      margin-bottom: 1rem;
    }
  
    .features-list {
      margin: 1.5rem 0;
    }
  
    .portfolio-content h3 {
      font-size: 1.2rem;
    }
  }

  /* Discount section */
  .discount-badge {
    background: linear-gradient(135deg, #ff4e50, #f9d423);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-weight: bold;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .package-pricing {
    margin: 1rem 0;
  }
  
  .original-price {
    text-decoration: line-through;
    color: #666;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
  
  .discounted-price {
    color: var(--primary-color);
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .additional-services-section {
    background-color: #F9F5FF;
    padding: 4rem 0;
  }
  
  .additional-services-grid {
    display: grid;
    gap: 3rem;
    margin-top: 2rem;
  }
  
  .service-category {
    margin-bottom: 2rem;
  }
  
  .service-category h3 {
    color: var(--secondary-color);
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .service-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .service-item-card {
    background: var(--white);
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: var(--shadow-md);
    transition: all 0.3s ease;
    border: 1px solid rgba(126, 58, 242, 0.1);
  }
  
  .service-item-card:hover {
    transform: translateY(-5px);
    box-shadow: var(--shadow-lg);
    border-color: var(--accent-color);
  }
  
  .service-item-card h4 {
    color: var(--secondary-color);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .service-price {
    color: var(--primary-color);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }
  
  @media (max-width: 768px) {
    .service-items {
      grid-template-columns: 1fr;
    }
  }