.navbar {
  background-color: var(--white);
  box-shadow: var(--shadow-sm);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.navbar-container {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color);
  text-decoration: none;
  z-index: 1001; /* Keep above mobile menu */
}

.nav-links {
  display: flex;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.2s ease;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.nav-link:hover {
  color: var(--primary-color);
}

/* Mobile Menu Button */
.mobile-menu-button {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001; /* Keep above mobile menu */
}

.mobile-menu-button span {
  width: 100%;
  height: 3px;
  background-color: var(--secondary-color);
  border-radius: 3px;
  transition: all 0.3s ease;
}

.mobile-menu-button.open span:first-child {
  transform: translateY(9px) rotate(45deg);
  background-color: var(--primary-color);
}

.mobile-menu-button.open span:nth-child(2) {
  opacity: 0;
}

.mobile-menu-button.open span:last-child {
  transform: translateY(-9px) rotate(-45deg);
  background-color: var(--primary-color);
}

/* Mobile Styles */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: flex;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--white);
    flex-direction: column;
    gap: 0;
    padding: 1rem 0;
    box-shadow: var(--shadow-md);
    border-top: 1px solid var(--light-gray);
  }

  .nav-links.open {
    display: flex;
    animation: slideIn 0.3s ease forwards;
  }

  .nav-links li {
    width: 100%;
  }

  .nav-link {
    display: block;
    padding: 1rem 2rem;
    width: 100%;
  }

  .nav-link:hover {
    background-color: #F9F5FF;
  }
}

/* Animation for mobile menu */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Prevent scrolling when mobile menu is open */
body.menu-open {
  overflow: hidden;
}