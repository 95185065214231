.footer {
  background: linear-gradient(to bottom, #2D283E, #231F33); /* Dark purple gradient */
  color: var(--white);
  padding: 4rem 0;
}
  
  .footer-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer-section h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .footer-links {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 0.75rem;
  }
  
  .footer-links a {
    color: var(--white);
    text-decoration: none;
    transition: all 0.2s ease;
    display: inline-block;
  }
  
  .footer-links a:hover {
    color: var(--accent-color);
    transform: translateX(5px);
  }
  
  .social-links {
    display: flex;
    gap: 1rem;
  }
  
  .social-links a {
    color: var(--white);
    text-decoration: none;
    transition: all 0.2s ease;
  }
  
  .social-links a:hover {
    color: var(--accent-color);
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  

  .contact-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--white);
    text-decoration: none;
    transition: all 0.2s ease;
    padding: 0.5rem 0;
  }
  
  .contact-link:hover {
    color: var(--accent-color);
    transform: translateX(5px);
  }
  
  .contact-icon {
    font-size: 1.2rem;
  }