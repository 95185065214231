.hero {
    padding: 8rem 0 4rem;
    text-align: center;
    background-color: var(--white);
    background: linear-gradient(135deg, var(--light-gray) 0%, #F3EBFF 100%);
  }
  
  .hero h1 {
    font-size: 3.5rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  
  .hero p {
    font-size: 1.25rem;
    color: var(--text-color);
    max-width: 800px;
    margin: 0 auto 2rem;
  }
  
  .btn {
    display: inline-block;
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .btn-primary {
    background-color: var(--primary-color);
    color: var(--white);
  }
  
  .btn-primary:hover {
    background-color: var(--primary-hover);
    transform: translateY(-2px);
  }
  
  .services {
    background-color: var(--white);
    background: linear-gradient(135deg, var(--light-gray) 0%, #F3EBFF 100%);
    padding: 4rem 0;
  }
  
  .services h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0 1rem;
    border-color: var(--accent-color);
  }
  
  .service-card {
    background-color: var(--white);
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: var(--shadow-md);
    transition: transform 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-5px);
    border-color: var(--accent-color);
  }
  
  .service-card h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  .cta-section {
    padding: 4rem 0;
    text-align: center;
  }
  
  .cta-section h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .cta-section p {
    font-size: 1.25rem;
    max-width: 600px;
    margin: 0 auto 2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero h1 {
      font-size: 2.5rem;
    }
    
    .services-grid {
      grid-template-columns: 1fr;
    }
    
    .footer-container {
      grid-template-columns: 1fr;
    }
    
    .nav-links {
      display: none;
    }
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 1s ease-out;
  }
  
  .slide-up {
    animation: slideUp 1s ease-out;
  }