.contact-hero {
    background-color: var(--light-gray);
    background: linear-gradient(135deg, var(--light-gray) 0%, #F3EBFF 100%);
    padding: 6rem 0 4rem;
    text-align: center;
  }
  
  .contact-hero h1 {
    font-size: 3rem;
    color: var(--secondary-color);
    margin-bottom: 1.5rem;
    opacity: 0;
    animation: fadeInDown 1s ease forwards;
  }
  
  .contact-hero p {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.2rem;
    color: var(--text-color);
    opacity: 0;
    animation: fadeIn 1s ease forwards 0.5s;
  }
  
  .contact-section {
    padding: 4rem 0;
    background-color: var(--white);
  }
  
  .contact-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    margin-top: 2rem;
  }
  
  /* Contact Info Styles */
  .contact-info {
    background-color: var(--white);
    border-left: 4px solid var(--primary-color);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid var(--light-gray);
  }
  
  .contact-info h2 {
    color: var(--secondary-color);
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }
  
  .info-item {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  
  .info-item-icon {
    color: var(--primary-color);
    font-size: 1.5rem;
    flex-shrink: 0;
  }
  
  .info-item-content h3 {
    color: var(--secondary-color);
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  
  .info-item-content p {
    color: var(--text-color);
    line-height: 1.5;
  }
  
  /* Form Styles */
  .contact-form {
    background-color: var(--white);
    padding: 2rem;
    border-radius: 12px;
    border: 1px solid var(--light-gray);
  }
  
  .contact-form h2 {
    color: var(--secondary-color);
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }
  
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group.full-width {
    grid-column: span 2;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--secondary-color);
    font-weight: 500;
  }
  
  .form-input,
  .form-select,
  .form-textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid var(--light-gray);
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(126, 58, 242, 0.1);
    border-radius: 8px;
    font-size: 1rem;
    color: var(--text-color);
    transition: all 0.3s ease;
  }
  
  .form-input:focus,
  .form-select:focus,
  .form-textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
  }
  
  .form-textarea {
    resize: vertical;
    min-height: 150px;
  }
  
  .form-submit {
    background-color: var(--primary-color);
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
  }
  
  .form-submit:hover {
    background-color: var(--primary-hover);
    transform: translateY(-2px);
  }
  
  .form-submit:disabled {
    background-color: var(--light-gray);
    cursor: not-allowed;
    transform: none;
  }
  
  /* Error States */
  .form-input.error,
  .form-select.error,
  .form-textarea.error {
    border-color: #dc2626;
  }
  
  .error-message {
    color: #dc2626;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  /* Success Message */
  .success-message {
    color: #059669;
    text-align: center;
    padding: 1rem;
    border-radius: 8px;
    background-color: #ecfdf5;
    margin-bottom: 1rem;
  }
  
  /* Animations */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-grid {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  
    .form-grid {
      grid-template-columns: 1fr;
    }
  
    .form-group.full-width {
      grid-column: span 1;
    }
  
    .contact-hero h1 {
      font-size: 2.5rem;
    }
  }